import config from "../Config/config";

const APP_ROUTES = {
    LOGIN: "/",
    ABOUT: "/about",
  
    SIGNOUT: "/signout",
  
};

const API_CONSTANTS = {
    APPLICATION_CODE: 'GO',
    APP_CODE: 'BACKOFFICE',
    APP_VERSION: 1,
    ENABLE_SAP:config?.REACT_APP_ENABLE_SAP_FLOW,
    DEFAULT_WALLET_COUPON: "GIWALLET01"

}

export { APP_ROUTES, API_CONSTANTS };
