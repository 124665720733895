import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Toast {
   

    constructor() {
        toast.configure();
    }

    success(msg, options = {}) {
        // toast.success(msg, {
        //     ...this.defaultOptions,
        //     ...options,
        // });
    }

    info(msg, options = {}) {
        toast.info(msg, {
            ...this.defaultOptions,
            ...options,
        });
    }

    warning(msg, options = {}) {
        toast.warning(msg, {
            ...this.defaultOptions,
            ...options,
        });
    }

   
}

export default new Toast();
