import StorageHelper from "./StorageHelper";
import jwt from "jsonwebtoken";
import config from "../Config/config";

class AuthHelper {
    authenticated = false;

    setGoAccessToken(accessControl){
        let token = jwt.sign({...accessControl}, config?.REACT_APP_ACCESS_TOKEN_SECRET_KEY, { expiresIn: '24h' });
        StorageHelper.set("goact", JSON.stringify(token));
    }

    getGoAccessToken(type){
        try {
            let token = JSON.parse(StorageHelper.get("goact"));
            var decoded = jwt.verify(token, config?.REACT_APP_ACCESS_TOKEN_SECRET_KEY);
            return decoded?.[type];
        } catch(err) {
            this.signout();
            return false;
        }
    }

    // isAuthenticated() {
    //     try {
    //         const { jwtToken } = this.getSessionData();
    //         if(jwtToken){
    //             this.authenticated = jwtToken;
    //             console.log({s: this.getSessionData()});

    //         }
           
    //     } catch (err) {
    //         console.log("Auth error auth helper", err);
    //     }
    //      return this.authenticated;
    // }


    isAuthenticated() {
        try {
            return sessionStorage.getItem('isLogin') === 'true';
        } catch (err) {
            console.log("Auth error auth helper", err);
            return false;
        }
    }
    

    getSessionData() {
        let authData = localStorage.getItem("auth");
        let userData = localStorage.getItem("userData");
    
        let jwtToken = null;
        let userDetail = null;
    
        if (authData) {
            jwtToken = JSON.parse(authData).jwtToken || null;
        }
    
        if (userData) {
            userDetail = JSON.parse(userData) || null;
        }
    
        return { userDetail, jwtToken };
    }
    
    getMemberData(){
        let authData = localStorage.getItem("auth") || "{}";
        const { jwtToken = null, userId = null, email = null } = JSON.parse(authData) || null;

        return { userId, jwtToken, email };
    }

    
    getSingleProp(key = null) {
        const sessionData = this.getSessionData();
        const user = sessionData || null;
        // console.log("getUser() Called", user);
        return key ? user?.[key] : user;
    }

    getAuthHeaders() {
        // const { token } = this.getSessionData();
        const {userId,jwtToken}=this.getMemberData();
        return {
            Authorization: `Bearer ${jwtToken}`,
            JWTToken: jwtToken,
            userId:userId,
        };
    }

    signout(callback) {
        localStorage.clear();

        if(callback) {
            callback();
        }
        // Toast.success("Logged Out Successfully!");
    }
}

export default new AuthHelper();
