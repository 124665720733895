import { configureStore } from '@reduxjs/toolkit';
import { reducer as AuthReducer } from './reducers/Auth';


export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    
  },
});
