import { ChangeEvent, Component } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { farmerView } from "../../Libraries/redux/reducers/Auth/reducer";
import { connect } from "react-redux";
import StorageHelper from "../../Utility/StorageHelper";
import feild from "../../assets/images/feild_logo.svg";
import address from "../../assets/images/address_logo.svg";
import mobile_logo from "../../assets/images/mobile_logo.svg";
import msg from "../../assets/images/msg.svg";
import up from "../../assets/images/uparrow.svg";
import side_arrow from "../../assets/images/side_arrow.svg";
import male_logo from "../../assets/images/male_logo.svg";
import female_logo from "../../assets/images/female_logo.svg";
import othersGender_logo from "../../assets/images/othersGender_logo.svg";

import user from "../../assets/images/user.svg";
import identity from "../../assets/images/idenitity.svg";
import close from "../../assets/images/close_logo.svg";
import added from "../../assets/images/added_logo.svg";
import reload from "../../assets/images/reload_logo.svg";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import theme from "../../Utility/theme";
import { Layout } from "../Layout";
import LoadingOverlay from "../Layout/LoadingOverlay";
import React from "react";
import { ToastOptions, ToastPosition, toast } from "react-toastify";


const useStyles = createStyles((theme: Theme) => ({
  container: {
    position: "relative",

    background: "white",
    width: "100%",
    height: "100%",
    
     maxHeight: "100vh",
    overflowX: "hidden",
    overflowY: "hidden",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
   

    [theme.breakpoints.down("sm")]: {
      justifyContent: "centre",
      alignItems: "center",
      padding: "20px 16px 20px 16px",
    },
  },

  placeHolder: {
    "::placeholder": {
      color: "black",
      fontSize: "28px",

      fontFamily: "'Open Sans', sans-serif",
      lineWeight: "19.07px",
    },
  },

  enrolledBy: {
    display: "flex",
    flexDirection: "row",
    marginTop: "-15px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginLeft: "0px",
    },
  },

  enrolledProject: {
    marginLeft: "23px",
    marginRight: "3px",
    fontSize: "14px",
    color: "#666666",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "-7px",
    },
  },

  overlay: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#00000080",
      zIndex: 999,
      display: "none",
    },
  },
  overlayVisible: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  tableRow1: {
    width: "193px",
    maxHeight: "45px",
    height: "100%",
    background: " rgba(26, 115, 232, 0.1)",
  },
  tableRow2: {
    width: "193px",
    maxHeight: "45px",
    height: "100%",
    background: "#FFFFFF",
  },

  container1: {
    background: "#F8FBFE",
    maxWidth: "1066px",
    width: "100%",
    alignItems: "left",
    gapItems: "10px",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    padding: "10px 0px 5px 20px",
    boxSizing: "border-box",
    marginTop: "4.5rem",
    radius: "4px",
    borderRadius: "4px",
    border: "1px solid #DCEAFC",
  },

  
  containerFeed: {
    background: "white",
    maxWidth: "1066px",
    width: "100%",
    alignItems: "center",
    gapItems: "10px",
    flexDirection: "column",

   
    display: "flex",
  
    position:'relative'
  
  },

  feedbackContainerVisible: {
    zIndex: 9999,

  
     transform: "translateY(-250px)",
   
    [theme.breakpoints.down("sm")]: {
      display:'flex',
      justifyContent:'center',
      transform: "translateY(-230px)",
      margin: "auto",
    position: "fix",
    bottom:'auto'
    },
  },

  feedbackContainer: {
    zIndex: 9999,

    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    flexWrap: "wrap",
    background: " linear-gradient(112.62deg, #3E4ABA 2.48%, #3CB3CB 96.67%);",
    width: "100%",
    maxWidth: "328px",
    maxHeight: "60px",

    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
      display:'flex',
      marginRight:'auto',

    },

    marginLeft:'auto',
 
    border: "0.5px solid #CCCCCC",
    borderRadius: "4px",

  },
  container2: {
    background: "#F8FBFE",
    maxWidth: "1066px",
    maxHeight: "256px",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
   
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "25px 10px 10px 20px",
    boxSizing: "border-box",
    marginTop: "10px",
    border: "1px solid #DCEAFC",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "294px",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
       },

  },

  feedbackIcon: {
    width: "32px",
    height: "50px",
    marginLeft: "15px",
    alignItems: "center",
  },
  feedbackInput: {
    marginLeft: "10px",
    marginRight: "10px",

    height: "100%",
    "&::placeholder": {
      color: " rgba(0, 0, 0, 0.3) ",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: "19.07px",
    },
  },
  root: {
    width: "100%",
    maxWidth: "478px",
    height: "100%",
    maxHeight: "700px",
    margin: "auto",
    overflowX: "auto",
    overflowY: "auto",
  },

  tableContainer: {
    borderRadius: "4px",

    width: "193px",
    overflowX: "hidden",
    overflowY: "hidden",
    marginBottom: "0px",
    radius:4,
    border: "1px solid  rgba(186, 213, 248, 1)",
  },
  avatar: {
    margin: theme.spacing(1),
    paddingBottom: "15px",
  },
  form: {
    width: "100%",
    height: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  non_gi_member_button: {
    marginTop: theme.spacing(2),
  },
  auth_title: {
    marginBottom: theme.spacing(2),
  },
  back: {
    display: "flex",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      opacity: 0.6,
    },
  },
}));

interface IProps {
  classes: any;
  authData: any;
  farmerData: any;
  payload: any;
  farmerView: any;
  isFeedbackVisible: boolean;
}

interface IState {
  mobile: any;
  errorMobile: any;
  feedbackSubmit: any;
  page: any;
  requesting: any;
  snackSuccessMessage: any;
  snackErrorMessage: any;
  fieldsEnrolledCount: any;
  fetchFarmerData: any;
  farmerData: any;
  farmerName: any;
  farmerAddress: any;
  mobileNumber: any;
  altMobile: any;
  fields: any;
  isFeedbackVisible: any;
  feedbackText: string;
  characterCount: number;
  inputValue: any;
  remainingChars: any;
  isSubmitted: boolean;
  feedbackValue: any;
  submittedFeedback: string;
  isSmallScreen: boolean;
  gender: any;
}

class About extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      mobile: "",
      errorMobile: false,
      page: "mobile",
      requesting: false,
      snackSuccessMessage: "",
      snackErrorMessage: "",
      fieldsEnrolledCount: "",
      fetchFarmerData: "",
      farmerData: "",
      farmerName: "",
      farmerAddress: "",
      mobileNumber: "",
      altMobile: "",
      fields: "",
      isFeedbackVisible: false,
      feedbackText: "",
      characterCount: 1000,
      feedbackSubmit: true,
      inputValue: "",
      remainingChars: "",
      isSubmitted: false,
      feedbackValue: "",
      submittedFeedback: "",
      isSmallScreen: true,
      gender:"",
    };
  }

  fetchFarmerData = async () => {
    const mobile = sessionStorage.getItem("mobile");
    //  const mobile ='6663332221';

    if (mobile) {
      let payload: any = { mobile };
      this.props.farmerView(payload).then((resp: any) => {
        let res = resp?.payload;
        this.setState({ requesting: false, farmerData: res.data });
      });
    }
  };

  componentDidMount() {
    // let localStorage =
    //   StorageHelper.getMultiple(["initiated-microsoft", "auth"]) || {};
    this.fetchFarmerData();

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;

    if (
      inputValue.length === 0 ||
      !inputValue.trim() ||
      /[a-zA-Z]/.test(inputValue)
    ) {
      if (inputValue.length <= 1000) {
        this.setState({ inputValue });
        this.setState({
          feedbackSubmit: inputValue.trim().length >= 10 ? false : true,
        });
      } else {
        event.preventDefault();
        return;
      }
    } else {
      event.preventDefault();
      return;
    }
  };

  handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const feedbackText = event.target.value;
    this.setState({
      feedbackText,
      characterCount: feedbackText.length,
    });
  };

  toggleFeedbackVisibility = () => {
    this.setState((prevState) => ({
      isFeedbackVisible: !prevState.isFeedbackVisible,
      feedbackText: "",
      characterCount: 1000,
      isSubmitted: false,
    }));
  };

  error = (msg: string) => {
    const defaultOptions: ToastOptions = {
      position: "top-right" as ToastPosition,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
    };

    toast.error(` ${msg}`, defaultOptions);
  };

  handleSubmitFeedback = async (feedbackValue: any) => {
    try {
      const farmerData = this.state.farmerData;
      this.setState({ feedbackSubmit: true, isSubmitted: true });
      const payload = {
        farmer_id: `${farmerData.farmer_id}`,
        farmer_Mobile: farmerData?.farmerMobile,
        farmer_Feedback: feedbackValue,
      };

      const response = await FnAuthenticatedRequest(
        "POST",
        "api/addFarmerKaFeedback",
        "",
        payload
      );

      if (response?.success) {
        this.setState({
          submittedFeedback: feedbackValue,
          inputValue: "",
        });
      } else {
        this.error("submitting feedback failed");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  componentWillUnmount() {
    // Clean up event listener
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const isSmallScreen = window.innerWidth < 600; // Adjust the breakpoint as needed
    this.setState({ isSmallScreen });
  };

  render() {
    console.log(othersGender_logo,"gender")
    const { classes } = this.props;
    const {
      farmerData,
      isFeedbackVisible,
      characterCount,
      inputValue,
      isSubmitted,
    } = this.state;
    const remainingChars = characterCount - inputValue.length;
    const isSubmitDisabled = inputValue.trim().length < 10;
    const farmerName = farmerData.farmerName || "None";
    const gender = farmerData.gender || "None";
    const farmerAddress = farmerData.address || "None";
    const district = farmerData.district || "None";
    const state = farmerData.state || "None";
    const taluka = farmerData.taluka || "None";
    const Village = farmerData.village || "None";
    const mobileNumber = farmerData.farmerMobile;
    const altMobile = farmerData.altMobile;
    const enrolled_project = farmerData.project_name || "None";
    const added_by = farmerData.added_by || "None";
    const farmer_id = farmerData.farmer_id;
    const fields = farmerData.fields || [];
    const fieldsEnrolledCount = fields.length;
    const isSmallScreen = this.state.isSmallScreen;



    return (
      <>
        {/* <div
          className={`${classes.overlay} ${
            isFeedbackVisible ? classes.overlayVisible : ""
          }`}
        /> */}

        

        <div className={classes.container}>
          <div className={classes.container1}>
            <div
              className={classes.imgContainer}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginLeft: "-8px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "22px", height: "22px", marginRight: "5px" }}
                  src={identity}
                  alt="identity "
                />
                <span
                  style={{
                    lineHeight: "24.51px",
                    color: "#333333",
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  Personal Info
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
              
              {gender === 'M' || gender === 'F' || gender === 'O' ? (
                <img
                  style={{ width: "25px", height: "30px", marginLeft: "-5px", marginRight: "4px" }}
                  src={
                    gender === 'M' ? male_logo :
                    gender === 'F' ? female_logo :
                    gender === 'O' ? othersGender_logo : ''
                  }
                  alt="Gender Logo"
                />
              ) : null}  
                <span
                  style={{
                    color: "#333333",
                    fontSize: "16px",
                    fontFamily: "'Open Sans', sans-serif",
                    // fontWeight: "350",
                  }}
                >
                  {farmerName}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  width: "100%",
                  flexWrap: "wrap",
                  
                }}
              >
                <img
                  style={{ width: "14px", height: "22px", marginRight: "0px" }}
                  src={mobile_logo}
                  alt="mobile "
                />
                <span style={{ marginRight: "15px", fontSize: "16px" , fontFamily: "'Open Sans', sans-serif",  color: "#333333",}}>
                  {mobileNumber}
                </span>

                {mobileNumber !== altMobile && altMobile !== "" && (
                  <>
                    <img
                      style={{
                        width: "44px",
                        height: "22px",
                        marginRight: "-11px",
                        borderLeft: "1px solid #D0D0D0",
                        paddingLeft: "10px",
                      }}
                      src={mobile_logo}
                      alt="mobile "
                    />
                    <span
                      style={{
                        width: "22px",
                        height: "22px",
                        fontSize: "16px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "#333333",


                      }}
                    >
                      {altMobile}{" "}
                    </span>
                  </>
                )}
              </div>

              {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: "100%",
              flexWrap: "wrap",
              marginTop: "2px", // Added margin for separation
            }}
          >
            <img
              style={{ width: "14px", height: "22px", marginRight: "0px"  }}
              src={gender === 'M' ? male_logo : gender === 'F' ? female_logo : gender === 'O' ? 'Others' : 'Unknown'}
              alt="gender"
            />
            <span style={{ marginRight: "15px", fontSize: "16px", fontFamily: "'Open Sans', sans-serif", color: "#333333" }}>
                {gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : gender === 'O' ? 'Others' : 'Unknown'}
            </span>
          </div> */}


              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  style={{ width: "14px", height: "20px", marginRight: "5px" }}
                  src={address}
                  alt="address "
                />
                <span
                  style={{
                    fontSize: "16px",
                    maxWidth: "calc(100% - 19px)",
                    overflowWrap: "break-word",
                    textAlign: "left",
                    fontFamily: "'Open Sans', sans-serif",
                    color: "#333333",


                  }}
                >
                  <span style={{ marginRight: "5px" }}>{farmerAddress}</span>,{" "}
                  {Village}, {taluka}, {district}, {state}
                </span>
              </div>
            </div>
          </div>

        

          <div className={classes.container2}>
            <div
              className={classes.enrolledBy}
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "5px",
                width: "100%",
                flexWrap: "wrap",
                marginBottom: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "-12px",
                }}
              >
                <img
                  style={{ width: "28px", height: "28px", marginRight: "3px",marginTop:'-3px' }}
                  src={feild}
                  alt="feild "
                />
                <span
                  style={{
                    marginRight: "3px",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: " #333333",
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: "24.51px",
                    height: "25px",
                  }}
                >
                  Fields Enrolled:
                </span>
                <span
                  className={classes.fieldDetails}
                  style={{
                    marginRight: theme.breakpoints.down(502) ? "3px" : "150px",
                    fontSize: "18px",
                  }}
                >
                  {fieldsEnrolledCount}
                </span>
              </div>

              <div>
                <span className={classes.enrolledProject}>Enrolled by:</span>
                <span
                  className={classes.fieldDetails}
                  style={{
                    marginRight: "20px",
                    fontSize: "14px",
                    color: " #1A1A1A",
                  }}
                >
                  {added_by}
                </span>
              </div>
              <div>
                <span className={classes.enrolledProject}>
                  Enrolled for Project:
                </span>
                <span
                  className={classes.fieldDetails}
                  style={{
                    marginRight: "3px",
                    fontSize: "14px",
                    color: " #1A1A1A",
                  }}
                >
                  {enrolled_project}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  marginTop: "5px",
                  flexWrap: "wrap",
                  flexDirection: isSmallScreen ? "column" : "row",
                  width: "100%",
                  maxWidth: "1066px",
                  marginLeft: "-8px",
                }}
              >
                {!isSmallScreen ? (
                  fields.map((field: any, index: any) => (
                    <TableContainer
                      key={index}
                      // component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table className={classes.table}>
                        <TableHead className={classes.tableRow1}>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Field ID
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Acre
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableRow2}>
                          <TableRow>
                            <TableCell>{`${farmer_id}_${field.feildNO}`}</TableCell>
                            <TableCell>{field.areaAc}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))
                ) : (
                  <TableContainer
                    // component={Paper}
                    className={classes.tableContainer}
                    style={{ flexDirection: "column" }}
                  >
                    <TableContainer
                      // component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table className={classes.table}>
                        {/* Common Table Head */}
                        <TableHead className={classes.tableRow1}>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Field ID
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Acre
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody className={classes.tableRow2}>
                          {fields.map((field: any, index: any) => (
                            <TableRow key={index}>
                              <TableCell>{`${farmer_id}_${field.feildNO}`}</TableCell>
                              <TableCell>{field.areaAc}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableContainer>
                )}
              </div>
            </div>
          </div>
          <LoadingOverlay isFeedbackVisible={isFeedbackVisible} />
          <Layout state={farmerData.state} children={undefined}/>
       

          <div className={classes.containerFeed}>
          
          
          <div
           
              className={`${classes.feedbackContainer}
             ${isFeedbackVisible ? classes.feedbackContainerVisible : ""}`}
              style={{
                maxHeight: isFeedbackVisible ? "50px" : "70px",
                borderRadius: isFeedbackVisible ? "4px 4px 0px 0px" : "4px",
                marginLeft:'auto'
              }}
              onClick={
                !isFeedbackVisible ? this.toggleFeedbackVisibility : undefined
              }
            >
              <img
                src={msg}
                className={classes.feedbackIcon}
                style={{
                  width: "38px",
                }}
              />
              <span
                className={classes.imageDetails}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "450",
                  marginLeft: "10px",
                  fontSize: "15px",
                  marginTop: "3px",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                Feedback/Grievance{" "}
              </span>
              {isFeedbackVisible ? (
                <img
                  src={close}
                  alt="Close "
                  onClick={this.toggleFeedbackVisibility}
                  style={{
                    cursor: "pointer",
                    marginLeft: isSmallScreen?'65px':"85px",
                    height: "14px",
                    width: "14px",
                  }}
                />
              ) : (
                <img
                  src={isSmallScreen ? side_arrow : up}
                  alt="up "
                  onClick={
                    isFeedbackVisible
                      ? this.toggleFeedbackVisibility
                      : undefined
                  }
                  style={{
                    cursor: "pointer",
                    marginLeft: isSmallScreen ? "80px" : "70px",
                    height: isSmallScreen ? "14px" : "28px",
                    width: isSmallScreen ? "10.18px" : "28px",
                  }}
                />
              )}

              {isFeedbackVisible && (
                <div
                  style={{
                    zIndex: 999,
                    marginLeft:'auto',
                    maxWidth: "328px",
                    minHeight: "258px",
                    maxHeight: "278px",
                    width: "100%",
                    height: "100%",
                    background: "#EAEAEA",
                    overflowY: "hidden",
                    marginTop: "-3px",
                    // bottom: 0,
                    border: "1px solid #CCCCCC",
                    borderRadius: " 0px 0px 4px 4px",
                  }}
                >
                  {isSubmitted ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "32px",
                          height: "32px",
                          marginTop: "25px",
                        }}
                        src={added}
                        alt="identity "
                      />
                      <span style={{ fontSize: "14px", marginTop: "15px" }}>
                        You’ve Successfully
                      </span>
                      <span style={{ fontSize: "14px", marginTop: "2px" }}>
                        Submitted Feedback
                      </span>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: "20px",
                          borderRadius: "4px",
                          border: "1px solid #1A73E8 ",
                          background: "#1A73E8",
                          gap: "2px",
                          width: "100%",
                          height: "100%",
                          maxWidth: "208px",
                          maxHeight: "43px",
                        }}
                        onClick={() => {
                          this.toggleFeedbackVisibility();
                          this.setState({
                            isSubmitted: false,
                            isFeedbackVisible: true,
                            inputValue: "",
                          });
                        }}
                      >
                        <img
                          style={{
                            width: "16px",
                            height: "16px",
                            font: "Open Sans",
                            marginRight: "5px",
                          }}
                          src={reload}
                          alt="identity "
                        />
                        <span style={{ fontSize: "13px", color: "#FFFFFF" }}>
                          Share another feedback
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <>
                      <textarea
                        className={`${classes.feedbackInput}`}
                        style={{
                          maxWidth: "296px",
                          width: "100%",
                          height: "146px",
                          marginTop: "10px",
                          padding: "10px",
                          resize: "none",
                          fontSize: "14px",
                        }}
                        placeholder="Type here..."
                        value={inputValue}
                        onChange={this.handleInputChange}
                      />
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: "12px",
                          paddingLeft: "15px",
                          color: "rgba(128, 128, 128, 1)",
                        }}
                      >
                        {remainingChars} characters left
                      </div>
                      <Button
                        variant="contained"
                        disabled={this.state.feedbackSubmit}
                        style={{
                          marginRight: "220px",
                          maxWidth: "73px",
                          minHeight: "43px",
                          width: "100%",
                          height: "100%",
                          marginTop: "10px",
                          backgroundColor: this.state.feedbackSubmit
                            ? "#CCCCCC"
                            : "#1A73E8",
                          color: this.state.feedbackSubmit
                            ? "#FFFFFF"
                            : "#FFFFFF",
                        }}
                        onClick={() => this.handleSubmitFeedback(inputValue)}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        
       
        </div>
       
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  farmerView: (payload: any) => dispatch(farmerView(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(About));
