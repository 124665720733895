export default class StorageHelper {
    static set(key, value) {
        value = typeof value === "object" ? JSON.stringify(value) : value;
        localStorage.setItem(key, value);
    }

    static setMultiple(data = {}) {
        let keys = Object.keys(data) || [];

        // eslint-disable-next-line array-callback-return
        return keys.map((key) => {
            let value = data?.[key];
            if (value) {
                StorageHelper.set(key, value);
            }
        });
    }

    static get(key) {
        return localStorage.getItem(key);
    }

    static getMultiple(keys) {
        let data = {};

        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
            data[key] = localStorage.getItem(key);
        });

        return data;
    }

    static removeMultiple(keys = []) {
        return keys.map((key) => localStorage.removeItem(key));
    }

    static remove(key) {
        return localStorage.removeItem(key);
    }

    static removeAll(){
        return localStorage.clear();
    }
}
