// import config from '../../Config/config';
// import { graphConfig } from '../../authConfig';

// import { API_CONSTANTS } from '../AppConstants';

const {
  REACT_APP_API_IAM_SYSTEM,
  REACT_APP_API_CARBON_API,
  REACT_APP_API_MEMBER_SYSTEM
  

} = process.env;

export default class Routes {
  static AUTH = class {
    static SEND_OTP = {
      baseUrl:  REACT_APP_API_IAM_SYSTEM,
      endPoint: '/api/Login/send-otp',
      method: 'POST',
      requireAuth: false,
    };
    static FARMER_VIEW= {
      baseUrl: REACT_APP_API_CARBON_API,
      endPoint: 'api/getFarmerKaView',
      method: 'POST',
      requireAuth: false,
    };

    static CHECK_FARMER= {
      baseUrl: REACT_APP_API_CARBON_API,
      endPoint: 'api/checkRegisterFarmer',
      method: 'POST',
      requireAuth: false,
    };

    static VERIFY_OTP = {
      baseUrl:  REACT_APP_API_IAM_SYSTEM,
      endPoint: '/api/Login/verify-otp',
      method: 'POST',
      requireAuth: false,
    };
    static SIGNOUT = {
      baseUrl: REACT_APP_API_IAM_SYSTEM,
      endPoint: '/api/signout',
      method: 'POST',
      requireAuth: false,
    };

   
  }
}
