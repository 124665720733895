import axios from "axios";
import AuthHelper from "../../Utility/AuthHelper";

export default class RESTClient {
    static default: any;

    client;

    headers: any;

    config;

    constructor(config: any) {
        this.config = config;

        let baseURL: string = this.config?.baseUrl || "";
        this.client = axios.create({
            baseURL
        });
    }

    call(payload: any, params = {}) {

        this.initHeaders();

        let { endPoint, method } = this.config;

        endPoint += typeof params === 'string' ? params : '';
            // console.log({endPoint})
        return this.client({
            method: method,
            url: endPoint,
            data: payload,
            headers: this.headers,
            params: params,
        });
    }

    callEx() {

        this.initHeaders();

        let { endPoint, method } = this.config;
            // console.log({endPoint})
        return this.client({
            method: method,
            url: endPoint,
            headers: this.headers,
            
        });
    }

    errorInterceptor() {
        this.client.interceptors.response.use(
            function (response) {
                // Do something with response data
                return response;
            },
            function (error) {
                // Do something with response error
                console.log(
                    "Axios Error Interceptor - ",
                    error?.response?.data
                );
                throw error;
            }
        );
    }

    initHeaders() {
        const { requireAuth = false, customHeaders = {} } = this.config;
        this.headers = requireAuth === true ? AuthHelper.getAuthHeaders() : {};
        this.headers = { ...this.headers, ...customHeaders };
        this.errorInterceptor();
    }
}
