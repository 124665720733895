import { useMediaQuery } from "@material-ui/core";
import React from "react";
import theme from "../../Utility/theme";


interface IProps {
    isFeedbackVisible: boolean;
  }
    const LoadingOverlay : React.FC<IProps> = ({  isFeedbackVisible }) => {

 

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (isSmallScreen) {
    return (
      <div style={{ position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", 
      zIndex: 9999, 
      display: isFeedbackVisible ? "block" : "none", }}>
      </div>
    );
  } else {
    return null; 
  }
};

export default LoadingOverlay;
