import { ChangeEvent, Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  createStyles,
  InputAdornment,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import carbon from "../../assets/images/carbon.png";
import farmer_logo from "../../assets/images/farmer_logo.svg";

const useStyles = createStyles((theme: Theme) => ({
  form: {
    width: "80%",
    maxWidth: "500px",
    height: "100%",
    maxHeight: "700px",
    margin: "auto",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  carbonImg: {
    height: "100%",
    maxHeight: "62px",
    width: "100%",
    maxWidth: "182px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95px",
      maxHeight: "32px",
      height: "100%",
      width: "100%",
    },
  },

  farmerImg: {
    height: "100%",
    maxHeight: "74px",
    width: "100%",
    maxWidth: "74px",
    margin: "auto",
  },
  submit: {
    width: "100%",
    maxWidth: "356px",
    height: "48px",
    background: "#1A73E8",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "328px",
    },
  },

  textFeild: {
    // '& .MuiInputLabel-outlined': {
    //   color: '#1A1A1A', // Set default label color
    // },
    // '& .MuiInputLabel-outlined.Mui-focused': {
    //   color: 'rgba(26, 115, 232, 1)',
    // },
    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-focused': {
    //     '& .MuiOutlinedInput-notchedOutline': {
    //       borderColor: 'rgba(26, 115, 232, 1)',
    //     },
    //   },
    // },
    width: "356px",
    height: "56px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "328px",
    },
  },

  image: {
    maxWidth: "100%",
    height: "auto",
  },
  styles: {
    fontSize: "20px",
    width: "100%",
    maxWidth: "164px",
    height: "100%",
    maxHeight: "27px",
    color: " #333333",
    lineHeight: "27.24px",
    fontFamily: "'Open Sans', sans-serif",
  },

  pstyle: {
    fontFamily: "'Open Sans', sans-serif",
    weight: "400",
    fontSize: "14px",
    maxHeight: "19.07px",
    align: "center",
    width: "100%",
    maxWidth: "277px",
    height: "100%",
    color: "#666666",

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      maxWidth: "238px",
      maxHeight: "16.74px",
      height: "100%",
      width: "100%",
      bottom: "20px,",
    },
  },
}));

interface IProps {
  classes: any;
  handleLogin: any;
  showAlert: any;
  sendOtpToMember: any;
  verifyOtp: any;
  farmerView: any;
  checkFarmer: any;
}

interface IState {
  mobile: any;
  errorMobile: any;
  otp: any;
  errorOtp: any;
  sendOtp: any;
  mobileExist: any;
  page: any;
  requesting: any;
  disabledResend: boolean;
  resendCountdown: number;
  failedAttempts: number;
  lastAttempt: number | null;
  blockError: boolean;
  verifyOtp: any;
  editMobile: any;
  sndOTPTopMargin: any;
}

class OtpLogin extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    const storedState = sessionStorage.getItem("otpLoginState");
    const storedBlockError = localStorage.getItem("blockError");
    if (storedState) {
      this.state = JSON.parse(storedState);
    } else {
      // alert(cookies.get("jwtToken"));
      this.state = {
        mobile: "",
        errorMobile: false,
        otp: "",
        errorOtp: false,
        mobileExist: false,
        page: "mobile",
        requesting: false,
        disabledResend: false,
        resendCountdown: 20,
        failedAttempts: 0,
        lastAttempt: null,
        blockError: false,
        sendOtp: false,
        verifyOtp: false,
        editMobile: false,
        sndOTPTopMargin: "20px",
      };
    }
  }

  componentDidMount() {
    sessionStorage.setItem("otpLoginState", JSON.stringify(this.state));
    localStorage.setItem("blockError", JSON.stringify(this.state.blockError));

    const storedMobile = sessionStorage.getItem("mobile");
    if (storedMobile) {
      this.setState({ mobile: storedMobile });
    }
    this.startResendCountdown();
  }

  startResendCountdown = () => {
    const intervalId = setInterval(() => {
      const { resendCountdown } = this.state;
      if (resendCountdown > 0) {
        this.setState({ resendCountdown: resendCountdown - 1 });
      } else {
        // Enable the resend button when countdown reaches 0
        this.setState({ disabledResend: false, blockError: false });
        clearInterval(intervalId);
      }
    }, 1000);
  };

  handleResendClick = () => {
    // Disable the resend button and start the countdown again
    this.setState({ disabledResend: true, resendCountdown: 20 }, () => {
      this.startResendCountdown();
    });
  };

  handleKeyDownSendOtp = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const button = document.getElementById("sendOtp");
      if (button) {
        button.click();
      }
    }
  };

  handleKeyDownVerifyOtp = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const button = document.getElementById("verifyOtp");
      if (button) {
        button.click();
      }
    }
  };

  handleEditMobile = (event: any) => {
    this.setState({ editMobile: false });
  };

  handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.state.editMobile) {
      return;
    }

    let mobile = event.target.value;

    const numericMobile = mobile.replace(/\D/g, "");

    if (numericMobile.length > 10) {
      mobile = numericMobile.slice(0, 10);
    } else {
      mobile = numericMobile;
    }

    this.setState({ mobile }, () => {
      this.validateMobile();
      sessionStorage.setItem("mobile", mobile);
    });
  };

  render() {
    const classes = this.props.classes;
    // const { mobile } = this.state;

    return (
      <>
        <div className={classes.form}>
          <img className={classes.carbonImg} src={carbon} alt="Carbon" />
          <p className={classes.pstyle}>
            Empowering Farmers, Nurturing The Earth
          </p>
          <img
            className={classes.farmerImg}
            style={{ marginTop: "20px" }}
            src={farmer_logo}
            alt="Farmer Logo"
          />
          <h2
            className={classes.styles}
            style={{
              marginTop: "6px",
              fontSize: "18px",
              width: "100%",
              maxWidth: "164px",
              height: "100%",
              maxHeight: "27px",
              color: " #333333",
              lineHeight: "27.24px",
              fontWeight: "600",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            Welcome, Sign In
          </h2>

          <Box component="form">
            <TextField
              className={classes.textFeild}
              type="tel"
              id="mobile"
              label="Phone Number"
              variant="outlined"
              margin="normal"
              fullWidth
              name="mobile"
              value={this.state.mobile}
              onChange={this.handleMobile}
              autoComplete="mobile"
              error={this.state.errorMobile || this.state.mobileExist}
              // InputLabelProps={{
              //   style: { color: "#1A1A1A" },
              // }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.page === "otp" && this.state.editMobile && (
                      <Button
                        onClick={this.handleEditMobile}
                        style={{ color: "#1A73E8", marginRight: "-20px" }}
                        disabled={this.state.blockError}
                      >
                        Edit
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
              onKeyDown={this.handleKeyDownSendOtp}
              helperText={
                (this.state.errorMobile && "Enter 10 Digit Mobile Number") ||
                (this.state.mobileExist &&
                  "Number is not Registered! Please contact +917065005511 for support")
              }
            />
          </Box>

          {this.state.page === "otp" && this.state.editMobile && (
            <Box component="form">
              <TextField
                margin="normal"
                className={classes.textFeild}
                id="OTP"
                fullWidth
                label="Enter 4 digit OTP"
                variant="outlined"
                name="OTP"
                onKeyDown={this.handleKeyDownVerifyOtp}
                onChange={this.handleOtp}
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/\D/g, "");

                  e.target.value = e.target.value.slice(0, 4);
                }}
                autoFocus
                error={this.state.errorOtp || this.state.blockError}
                helperText={
                  (this.state.errorOtp &&
                    "Incorrect or Invalid OTP, try resend") ||
                  (this.state.blockError &&
                    "Too many attempts, retry in 10 minutes")
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.resendCountdown > 0 && (
                        <Button
                          disabled={this.state.disabledResend}
                          onClick={this.handleResendClick}
                          style={{
                            color: "#808080",
                            width: "100%",
                            maxWidth: "102px",
                            marginLeft: "5px",
                          }}
                        >
                          Resend in {this.state.resendCountdown}
                        </Button>
                      )}
                      {this.state.resendCountdown === 0 && (
                        <Button
                          onClick={this.sendOtp}
                          style={{ color: "#1A73E8",marginRight: "-10px" }}
                          disabled={this.state.blockError}
                        >
                          Resend
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  minLength: 4,
                  maxLength: 4,
                  inputMode: "numeric",
                }}
              />
            </Box>
          )}

          {!this.state.editMobile && (
            <Button
              id="sendOtp"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.mobileExist}
              className={classes.submit}
              disabled={
                this.state.requesting ||
                this.state.errorMobile ||
                this.state.blockError ||
                this.state.mobileExist
              }
              style={{
                width: "356px",
                height: "48px",
                fontSize: "16px",
                marginTop: this.state.sndOTPTopMargin,
              }}
            >
              Send OTP
            </Button>
          )}

          {this.state.page === "otp" && this.state.editMobile && (
            <Button
              id="verifyOtp"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.verifyOtp}
              className={classes.submit}
              disabled={this.state.blockError || this.state.errorOtp}
              style={{
                width: "356px",
                height: "48px",
                fontSize: "16px",
              }}
            >
              Verify OTP
            </Button>
          )}
        </div>
      </>
    );
  }

  handleOtp = (event: ChangeEvent<HTMLInputElement>) => {
    let otp = event.target.value;

    const numericOtp = otp.replace(/\D/g, "");

    if (numericOtp.length > 4) {
      otp = numericOtp.slice(0, 4);
    } else {
      otp = numericOtp;
    }

    // Update the state with the sanitized input value
    this.setState({ otp }, () => {
      this.validateOtp();
    });
  };

  //Validate form on form submit or button click
  validateMobile = () => {
    const mobile = this.state.mobile;
    const firstDigit = mobile[0];

    if (mobile && mobile.length === 10 && mobile[0] !== "0") {
      const firstDigit = mobile[0];
      const allDigitsSame = mobile
        .split("")
        .every((digit: any) => digit === firstDigit);

      if (!allDigitsSame) {
        this.setState({ blockError: false });
        this.setState({ errorMobile: false });
        this.setState({ mobileExist: false, sndOTPTopMargin: "20px" });
        return;
      }
    }

    // If the conditions are not met or the first digit is '0', set error state
    this.setState({ blockError: false });
    this.setState({ errorMobile: true });
    this.setState({ mobileExist: false, sndOTPTopMargin: "20px" });
  };

  //Validate otp on form submit or button click
  validateOtp = () => {
    let otp: string = this.state.otp;

    if (otp && otp.length === 4) {
      this.setState({ errorOtp: false });
    } else {
      this.setState({ errorOtp: true });
    }
  };

  sendOtp = async (e: any) => {
    this.handleResendClick();

    e.preventDefault();

    let error = this.state.errorMobile;

    if (!error) {
      if (this.state.mobile == "") {
        await this.validateMobile();
        return;
      }

      this.setState({ requesting: true, sendOtp: true });

      let payload: any = { mobile: this.state.mobile, server: "carbon" };
      let response: any = this.props
        .sendOtpToMember(payload)
        .then((resp: any) => {
          let res = resp?.payload;

          if (res && res?.data?.status === "success") {
            this.setState({ page: "otp", editMobile: "false" });
          }

          this.setState({ requesting: false });
        });
    }
  };
  mobileExist = async (e?: any) => {
    e.preventDefault();

    let error = this.state.errorMobile;

    if (!error) {
      if (this.state.mobile == "") {
        await this.validateMobile();
        return;
      }

      this.setState({ requesting: true });

      let payload: any = { mobile: this.state.mobile };
      let response: any = this.props.checkFarmer(payload).then((resp: any) => {
        sessionStorage.setItem("farmarData", response.data);

        let res = resp?.payload;

        if (res?.success != false || res?.data!='' || res?.error!="No Data Found for Farmer!") {
          this.sendOtp(e);
        } else if (res?.success === false || res?.data===''||res?.error==="No Data Found for Farmer!") {
        

          this.setState({ mobileExist: true, sndOTPTopMargin: "45px" });
        }

        this.setState({ requesting: false });
      });
    }
  };
  verifyOtp = async (e: any) => {
    e.preventDefault();
    const { failedAttempts, lastAttempt, blockError } = this.state;

    // Function to set blockError in local storage
    const setBlockErrorInLocalStorage = (value: any) => {
      localStorage.setItem("blockError", value.toString());
    };

    if (!blockError) {
      this.setState({ requesting: true });

      const currentTime = Date.now();
      const tenMinutesInMillis = 10 * 60 * 1000;

      if (lastAttempt && currentTime - lastAttempt < tenMinutesInMillis) {
        const newFailedAttempts = failedAttempts + 1;
        if (newFailedAttempts > 3) {
          this.setState({
            blockError: true,
            failedAttempts: newFailedAttempts,
          });
          setBlockErrorInLocalStorage(true); // Set blockError in local storage
          setTimeout(() => {
            this.setState({
              failedAttempts: 0,
              lastAttempt: null,
              blockError: false,
            });
            setBlockErrorInLocalStorage(false); // Reset blockError in local storage
          }, 10 * 60 * 1000);
          return;
        } else {
          this.setState({
            failedAttempts: newFailedAttempts,
            lastAttempt: currentTime,
            blockError: false,
          });
          setBlockErrorInLocalStorage(false); // Reset blockError in local storage
        }
      } else {
        this.setState({ failedAttempts: 1, lastAttempt: currentTime });
      }

      let resp: any = await this.props.verifyOtp({
        mobile: this.state.mobile,
        otp: this.state.otp,
        server: "carbon",
      });

      let res = resp?.payload;

      if (res && res?.data?.status === "success") {
        this.props.handleLogin({ otp_login: res.data.data });
      } else {
        this.setState({ errorOtp: true });
      }

      this.setState({ requesting: false });
    }
  };
}

export default withStyles(useStyles)(OtpLogin);
