import { Component } from "react";
import {
  Card,
  CardContent,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SnackBar from "../Alert";
import OtpLogin from "./OtpLogin";
import {
  sendOtpToMember,
  verifyOtp,
  farmerView,
  checkFarmer,
} from "../../Libraries/redux/reducers/Auth/reducer";
import { connect } from "react-redux";
import history from "../../history";
import { APP_ROUTES } from "../../Libraries/AppConstants";
import StorageHelper from "../../Utility/StorageHelper";

const useStyles = createStyles((theme: Theme) => ({
  container: {
    background: "#F8FBFE",
    width: "100%",
    height: "100%",
    minHeight: "97vh",
    maxHeight: "700px",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      background: "#FFFFFF",

    

    },
    
  },
  root: {
    width: "100%",
    maxWidth: "448px",
    height: "100%",
    maxHeight: "700px",
    border: "1px solid #BAD5F8",
    margin: "auto",
    
    [theme.breakpoints.down("sm")]: {
      marginTop:'0px',

      border: "none",

    },
  },
}));

interface IProps {
  classes: any;
  sendOtpToMember: any;
  verifyGoogleLogin: any;
  verifyOtp: any;
  addSeller: any;
  farmerView: any;
  checkFarmer: any;
}

interface IState {
  mobile: any;
  errorMobile: any;
  otp: any;
  errorOtp: any;
  page: any;
  requesting: any;
  snackSuccessMessage: any;
  snackErrorMessage: any;
  loginWithOtp: any;
}

class Login extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      mobile: "",
      errorMobile: false,
      otp: "",
      errorOtp: false,
      page: "mobile",
      requesting: false,
      snackSuccessMessage: "",
      snackErrorMessage: "",
      loginWithOtp: false,
    };
  }

  render() {

    const classes = this.props.classes;
    return (
      <>
            <div className={classes.container} > 

          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  {!this.state.loginWithOtp && (
                    <>
                      <OtpLogin
                        sendOtpToMember={this.props.sendOtpToMember}
                        verifyOtp={this.props.verifyOtp}
                        checkFarmer={this.props.checkFarmer}
                        farmerView={this.props.farmerView}
                        handleLogin={this.handleLogin}
                        showAlert={this.alertMessage}
                      ></OtpLogin>
                    </>
                  )}
                </div>
                {this.state.snackSuccessMessage && (
                  <SnackBar
                    open={this.state.snackSuccessMessage ? true : false}
                    severity={"success"}
                    message={this.state.snackSuccessMessage}
                  ></SnackBar>
                )}
                {this.state.snackErrorMessage && (
                  <SnackBar
                    open={this.state.snackErrorMessage ? true : false}
                    severity={"error"}
                    message={this.state.snackErrorMessage}
                  ></SnackBar>
                )}
              </Container>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  nonGiHandle = () => {
    this.setState({ loginWithOtp: true });
  };

  alertMessage = (type: string, message: string) => {
    this.setState({ snackSuccessMessage: "", snackErrorMessage: "" });

    if (type === "success") {
      this.setState({ snackSuccessMessage: message });
    } else {
      this.setState({ snackErrorMessage: message });
    }
  };

  handleLogin = (status: any) => {};
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  sendOtpToMember: (payload: any) => dispatch(sendOtpToMember(payload)),
  farmerView: (payload: any) => dispatch(farmerView(payload)),
  checkFarmer: (payload: any) => dispatch(checkFarmer(payload)),

  verifyOtp: (payload: any) =>
    dispatch(verifyOtp(payload)).then((response: any) => {
      if (response?.payload?.data?.status === "success") {
        sessionStorage.setItem("isLogin", "true");
        return history?.replace(APP_ROUTES?.ABOUT);
      } else {
        sessionStorage.setItem("isLogin", "false");

        history?.replace(APP_ROUTES?.LOGIN);
      }
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Login));
