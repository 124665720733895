const {
    REACT_APP_BASE_URL,
    REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
    REACT_APP_MICROSOFT_LOGIN_CLIENT_ID,
    REACT_APP_GI_TENANT_URL,
 
    REACT_APP_ENABLE_COMMISSION,
    REACT_APP_ACCESS_TOKEN_SECRET_KEY,
    REACT_APP_IAM_DASHBOARD,
    REACT_APP_ENABLE_IAM,
  
    REACT_APP_OFFERS_DISPLAY_ON_APPCODES,
    REACT_APP_ENABLE_RETAILERS_REPORT,
    REACT_APP_ENABLE_COMPARE_CONFIG,
    REACT_APP_ENABLE_INVENTORY_REPORT,
    REACT_APP_ENABLE_MANUAL_ORDER_OFFERS,
    REACT_APP_ENABLE_DETAILED_ORDER_REPORT,
    REACT_APP_ENABLE_ORDER_STATUS_REPORT,
    REACT_APP_ENABLE_PAYMENT_COLLECTION_REPORT,
    REACT_APP_API_BACKEND_API,
   

    REACT_APP_ENABLE_RFC_REPORT_OFFER,
    REACT_APP_ENABLE_RFC_REPORT_KHATA,
    REACT_APP_ENABLE_OFFLINE_PAYMENT_DASHBOARD,
    REACT_APP_ENABLE_ADVANCE_PAYMENT_DASHBOARD,

    REACT_APP_ENABLE_MICROSOFT_SSO
} = process.env;

let config = {
    REACT_APP_BASE_URL: REACT_APP_BASE_URL || "",
    GOOGLE_LOGIN_CLIENT_ID: REACT_APP_GOOGLE_LOGIN_CLIENT_ID || "",
    MiCROSOFT_LOGIN_CLIENT_ID: REACT_APP_MICROSOFT_LOGIN_CLIENT_ID || "",
    MiCROSOFT_TENANT_ID: REACT_APP_GI_TENANT_URL || "",

    REACT_APP_ENABLE_DATADUMPSQL: true,
    REACT_APP_ENABLE_COMMISSION: (REACT_APP_ENABLE_COMMISSION)?.toLowerCase() === 'true',
    REACT_APP_ACCESS_TOKEN_SECRET_KEY: REACT_APP_ACCESS_TOKEN_SECRET_KEY || "",
    REACT_APP_IAM_DASHBOARD: REACT_APP_IAM_DASHBOARD || "https://bo-iam.testgrowindigo.co.in/",
    REACT_APP_ENABLE_IAM: REACT_APP_ENABLE_IAM?.toLowerCase() == 'true',
    REACT_APP_OFFERS_DISPLAY_ON_APPCODES: REACT_APP_OFFERS_DISPLAY_ON_APPCODES || "",
    REACT_APP_ENABLE_RETAILERS_REPORT: REACT_APP_ENABLE_RETAILERS_REPORT?.toLowerCase() == 'true',
    REACT_APP_ENABLE_COMPARE_CONFIG: REACT_APP_ENABLE_COMPARE_CONFIG?.toLowerCase() == 'true',
    REACT_APP_ENABLE_INVENTORY_REPORT: REACT_APP_ENABLE_INVENTORY_REPORT?.toLowerCase() == 'true',
    REACT_APP_ENABLE_MANUAL_ORDER_OFFERS: REACT_APP_ENABLE_MANUAL_ORDER_OFFERS?.toLowerCase() == 'true',
    REACT_APP_ENABLE_DETAILED_ORDER_REPORT: REACT_APP_ENABLE_DETAILED_ORDER_REPORT?.toLowerCase() == 'true',
    REACT_APP_ENABLE_ORDER_STATUS_REPORT: REACT_APP_ENABLE_ORDER_STATUS_REPORT?.toLowerCase() === 'true',
    REACT_APP_ENABLE_PAYMENT_COLLECTION_REPORT: REACT_APP_ENABLE_PAYMENT_COLLECTION_REPORT?.toLowerCase() == 'true',
    REACT_APP_API_BACKEND_API: REACT_APP_API_BACKEND_API || "",
   

    REACT_APP_ENABLE_RFC_REPORT_OFFER:REACT_APP_ENABLE_RFC_REPORT_OFFER?.toLowerCase() === 'true',
    REACT_APP_ENABLE_RFC_REPORT_KHATA:REACT_APP_ENABLE_RFC_REPORT_KHATA?.toLowerCase() === 'true',
    REACT_APP_ENABLE_OFFLINE_PAYMENT_DASHBOARD:REACT_APP_ENABLE_OFFLINE_PAYMENT_DASHBOARD?.toLowerCase()==='true',
    REACT_APP_ENABLE_ADVANCE_PAYMENT_DASHBOARD:REACT_APP_ENABLE_ADVANCE_PAYMENT_DASHBOARD?.toLowerCase()==='true',

    REACT_APP_ENABLE_MICROSOFT_SSO: REACT_APP_ENABLE_MICROSOFT_SSO?.toLowerCase() === 'true',
}

export default config;