import React from "react";
// import clsx from "clsx";

import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  useMediaQuery,
 
} from "@mui/material";
import carbonlogo from "../../assets/images/carbon.png";
import faq from "../../assets/images/faq.svg";
import logout from "../../assets/images/logout.svg";
import { useHistory } from "react-router";
import theme from "../../Utility/theme";

interface IProps {
  children: React.ReactNode;
  state: string;
}

const Layout: React.FC<IProps> = ({ children, state }) => {
  const history: any = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isLargerScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleFQLClick = () => {
    const Pdfstate = state?.toUpperCase();
    let pdfFilePath;
    switch (Pdfstate) {
      case "MAHARASHTRA":
        pdfFilePath = "/FAQs - Marathi.pdf";
        break;
      case "PUNJAB":
        pdfFilePath = "/FAQs - Punjabi.pdf";
        break;
      case "GUJARAT":
        pdfFilePath = "/FAQs - Gujrati.pdf";
        break;
      case "MADHYA PRADESH":
        pdfFilePath = "/FAQs - Hindi.pdf";
        break;

      default:
        pdfFilePath = "/faq.v1-for-farmer-portal-29022024.pdf";
        break;
    }

    fetch(pdfFilePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching PDF file:", error);
      });
  };

  const handleClick = () => {
    // Clear session storage
    sessionStorage.clear();
    // Redirect to the home page
    history.push("/");
  };

  return (
    <>
      <div style={{ background: "#fff" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{
            background: "#fff",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          // className={clsx("transition-all", {})}
        >
          <Toolbar
            style={{
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              minHeight: "56px",
              maxWidth: "1066px",
              margin: "auto",
            }}
          >
            {/* Logo */}
            <Typography>
              <img
                style={{
                  width: "160%",
                  height: "auto",
                  maxWidth: isSmallScreen ? "95px" : "131px",
                  maxHeight: isSmallScreen ? "32px" : "43px",
                  // marginLeft:'auto'
                  marginLeft: isSmallScreen ? "auto" : "-22px",
                }}
                src={carbonlogo}
                alt="growinlogo Image"
                className="h-10 cursor-pointer ml-2.5"
              />
            </Typography>
            <div style={{ flex: 1 }}></div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                marginLeft: isSmallScreen ? "20px" : "20px",
                marginRight: isSmallScreen ? "auto" : "-27px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  maxWidth: isSmallScreen ? "66px" : "74px",
                  height: "auto",
                  maxHeight: isSmallScreen ? "35px" : "43px",
                  cursor: "pointer",
                }}
                src={faq}
                alt="FAQ Image"
                className="h-10 cursor-pointer ml-2.5"
                onClick={handleFQLClick}
              />
              <img
                style={{
                  width: "100%",
                  maxWidth: isSmallScreen ? "85px" : "93px",
                  height: "auto",
                  maxHeight: isSmallScreen ? "35px" : "43px",
                  cursor: "pointer",
                }}
                src={logout}
                alt="Logout Image"
                className="h-10 cursor-pointer ml-2.5"
                onClick={handleClick}
              />
            </div>
          </Toolbar>
        </AppBar>

        <div
          style={{
            transition: "margin 0.3s",
          }}
        >
          <main className="mt-14">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
