import Toast from "./Toast";

export function handleResponse(action) {
    const response = action?.payload;
    
    const success = response?.error === "" || response?.success === true || response?.apiErrorCode === 0;
    // console.log({response})
    let msg =
        response?.data?.msg ||
        response?.data?.message ||
        response?.success?.message ||
        response?.error?.message ||
        response?.error;

    if (success) {
        if(msg){
            Toast.success(msg);
        }
    } else {
        if(msg){
            Toast.error(msg);
        }
    }
}
